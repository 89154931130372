/* General Styles */
.boto-gallery {
  background-color: #ffffff;
  padding: 2rem 0;
  text-align: center;
}

.gallery-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  gap: 2rem; /* Larger gap for bigger images */
  padding: 3.5rem;
  scroll-snap-type: x mandatory; /* Smooth snapping when scrolling */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.gallery-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit browsers */
}

.gallery-item {
  flex: 0 0 auto; /* Prevent items from shrinking */
  width: 400px; /* Default width for non-focused items */
  text-align: center;
  scroll-snap-align: center;
  position: relative;
  transition: transform 0.3s ease, width 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: 400px; /* Increased height */
  object-fit: cover;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-item-focused {
  width: 500px; /* Enlarge the focused image */
  transform: scale(1.2); /* Zoom effect */
  z-index: 10;
}

.gallery-caption {
  margin-top: 0.5rem;
}

.gallery-caption h3 {
  font-size: 1.5rem; /* Larger font for bigger images */
  margin: 0;
  color: #333;
}

.gallery-caption p {
  font-size: 1rem;
  color: #777;
}
.gallery-heading {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 5rem;
  color: #333;
  text-align: center;
  margin: 50px 0;
  letter-spacing: 2px;
}
