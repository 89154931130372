.process-container {
    display: flex;
    justify-content: space-between;
    padding: 60px;
    align-items: stretch; /* Ensures both sections stretch to equal height */
    background-color: white; /* Light background */
  }
  
  .process-content {
    flex: 1; /* Makes the text container flexible */
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers the text vertically */
  }
  
  .process-content h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #2c3e50; /* Darker text */
    margin-bottom: 2rem;
  }
  
  .process-steps {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .process-step {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    color: #34495e; /* Muted dark text */
  }
  
  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: #007bff; /* Blue text */
    background-color: #e9f7fe; /* Light blue background */
    border-radius: 50%;
    margin-right: 1rem;
  }
  
  .process-image {
    flex: 1; /* Makes the image container flexible */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .process-image img {
    width: 100%; /* Ensures the image scales correctly */
    height: auto; /* Maintains aspect ratio */
    max-height: 100%; /* Prevents the image from exceeding the height of the container */
    object-fit: cover; /* Ensures the image fills the space without distortion */
    border-radius: 10px;
  }
  .gallery-heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 5rem;
    color: #333;
    text-align: center;
    margin: 50px 0;
    letter-spacing: 2px;
  }

  @media (max-width: 768px) {
    .process-container {
      flex-direction: column; /* Stack content vertically */
    }
  
    .process-content {
      margin-right: 0; /* Remove margin for stacked layout */
      margin-bottom: 2rem; /* Add spacing between sections */
    }
  
    .process-image img {
      max-width: 100%; /* Adjust image width for smaller screens */
      max-height: none; /* Remove height constraint */
    }
  }
  