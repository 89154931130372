.copyright {
    background-color: #0a1612;
    color:white;
    font-size: 14px;
    text-align: center;
}
.icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px
}