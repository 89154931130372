.service-icons-container {
    display: flex;
    justify-content: space-evenly;
    gap: 2rem; /* Space between each box */
    padding: 2rem;
    background-color: #c19a6b;
  }
  
  .service-icon-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #333; /* Default text color */
    font-family: Arial, sans-serif;
  }
  
  .icon {
    font-size: 3rem; /* Icon size */
    color: white; /* Icon color */
    margin-bottom: 0.5rem;
  }
  
  .title {
    font-size: 1rem;
    font-weight: bold;
    color: white; /* Title text color */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .service-icons-container {
      flex-wrap: wrap; /* Stack items on smaller screens */
      gap: 1.5rem;
    }
  
    .icon {
      font-size: 2.5rem; /* Adjust icon size for smaller screens */
    }
  
    .title {
      font-size: 0.9rem; /* Adjust font size for smaller screens */
    }
  }
  