.careers-page {
    background-color: #f5f7fa;
    padding: 20px;
    padding-bottom: 60px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .careers-header {
    margin-bottom: 2rem;
  }
  
  .careers-header h1 {
    font-size: 2.5rem;
    color: #1a2930;
    margin-bottom: 0.5rem;
  }
  
  .careers-header p {
    font-size: 1.2rem;
    color: #4a4a4a;
    line-height: 1.5;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .careers-form-container {
    max-width: 500px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 8px;
  }
  
  .careers-form .form-group {
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  .careers-form label {
    display: block;
    font-size: 1rem;
    color: #1a2930;
    margin-bottom: 0.5rem;
  }
  
  .careers-form input[type="text"],
  .careers-form input[type="email"],
  .careers-form input[type="file"] {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .careers-form input[type="file"] {
    padding: 0.5rem;
  }
  
  .submit-btn {
    background-color: #1a2930;
    color: #fff;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #34495e;
  }
  .gallery-heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 5rem;
    color: #333;
    text-align: center;
    margin: 50px 0;
    letter-spacing: 2px;
  }
  
  @media (max-width: 768px) {
    .careers-header h1 {
      font-size: 2rem;
    }
  
    .careers-header p {
      font-size: 1rem;
    }
  
    .careers-form-container {
      padding: 1.5rem;
    }
  }
  