/* Overall Container */
.about-us {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  /* Top Section */
  .about-us-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(73, 21, 21); /* Soft blue background */
    padding: 3rem 2rem;
  }
  
  .about-us-heading-container {
    flex: 1;
    padding-right: 2rem;
  }
  
  .about-us-heading {
    font-size: 2.5rem;
    font-weight: bold;
    font-family: "Georgia", serif;
    color: #333;
  }
  
  .about-us-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .about-us-image-container img {
    width: 20%; /* Reduced the image size to 20% of its container */
    max-width: 300px; /* Set a maximum width for smaller screens */
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  /* Bottom Section */
  .about-us-bottom {
    background-color: #ffffff; /* White background */
    padding: 2rem;
    text-align: left;
  }
  
  .about-us-description {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    font-family: "Arial", sans-serif;
    margin: 0 auto;
    max-width: 800px;
  }

  .gallery-headings {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 5rem;
    color: white;
    text-align: center;
    margin: 50px 0;
    letter-spacing: 2px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-us-top {
      flex-direction: column;
      text-align: center;
      padding: 2rem 1rem;
    }
  
    .about-us-heading-container {
      padding-right: 0;
      margin-bottom: 1rem;
    }
  
    .about-us-image-container img {
      width: 80%;
    }
  
    .about-us-description {
      font-size: 1rem;
    }
  }
  