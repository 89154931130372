.info-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding-bottom: 60px;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
  }
  
  .text-content {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    height: 80%; /* 80% of the grid container height */
  }
  
  .image-gallery {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%; /* Full height of the container */
  }
  
  .large-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .small-images {
    display: flex;
    gap: 10px;
  }
  
  .small-images img {
    width: calc(50% - 5px);
    height: auto;
    border-radius: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .info-section {
      grid-template-columns: 1fr; /* Stack text and images */
    }
  
    .text-content {
      height: auto; /* Let text height adjust naturally */
    }
  }
  
  @media (max-width: 480px) {
    .text-content h1 {
      font-size: 1.5rem;
    }
  
    .text-content p {
      font-size: 0.9rem;
    }
  }
  