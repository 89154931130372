.nav-tabs .nav-link {
    color: black;
}

.nav-tabs .nav-link.active {
    color: #FECC13;
}
  
  .pagination .page-item .page-link {
    color: black;
    border: 1px solid black;
  }
  
  .pagination .page-item.active .page-link {
    background-color: #FECC13;
    border-color: black;
    color: black;
  }
  .gallery-heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 5rem;
    color: #333;
    text-align: center;
    margin: 50px 0;
    letter-spacing: 2px;
  }