.services {
    padding: 2rem 1rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .services-heading {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns in a row */
    gap: 1.5rem;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .service-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .service-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .service-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .service-text {
    font-size: 1rem;
    color: #666;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
    }
  }
  
  @media (max-width: 480px) {
    .services-grid {
      grid-template-columns: 1fr; /* Single column on small screens */
    }
  }
  