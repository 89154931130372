/* Navbar styling */
.navbarrr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem !important;
    background-color: #c19a6b; 
    color: white;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  
  /* Logo container styling */
  .navbarr-logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Logo image styling */
  .navbar-logo-image {
    width: 40px;
    height: auto;
  }
  
  /* Logo text styling */
  .navbar-logo h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    color: white;
  }
  
  /* Navigation links */
  .navbar-links {
    display: flex;
    gap: 2rem;
    list-style: none;
    margin: 0;
    transition: transform 0.3s ease-in-out;
  }
  
  .navbar-links li a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    transition: color 0.3s;
  }
  
  .navbar-links li a:hover {
    color: #f0a500;
  }
  
  /* Contact button styling */
  .navbar-contact {
    display: flex;
  }
  
  .contact-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .contact-button:hover {
    background-color: white;
    color: black;
  }
  
  /* Hamburger menu */
  .navbar-hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
  }
  
  .hamburger-icon {
    width: 25px;
    height: 3px;
    background-color: white;
    display: block;
    position: relative;
  }
  
  .hamburger-icon::before,
  .hamburger-icon::after {
    content: "";
    width: 25px;
    height: 3px;
    background-color: white;
    position: absolute;
    left: 0;
    transition: transform 0.3s ease-in-out;
  }
  
  .hamburger-icon::before {
    top: -8px;
  }
  
  .hamburger-icon::after {
    top: 8px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .navbar-links {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: black;
      flex-direction: column;
      width: 93%;
      transform: translateY(-100%);
      opacity: 0;
      visibility: hidden;
    }
  
    .navbar-links.open {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  
    .navbar-links li {
      text-align: left;
      padding: 1rem 0;
    }
  
    .navbar-contact {
      display: none;
    }
  
    .navbar-hamburger {
      display: block;
    }
  }
  