.home {
    background: url('../assets/home.jpg') no-repeat center center/cover;
    margin-bottom: 5rem; /* Adds space between Home and InfoSection */;
  }
  
  @media (max-width: 768px) {
    .home {
      margin-bottom: 3rem; /* Adjust spacing for medium screens */
    }
  }
  
  @media (max-width: 480px) {
    .home {
      margin-bottom: 2rem; /* Adjust spacing for smaller screens */
    }
  }
  
  /* Main section styles */
  .home-section {
    height: 100vh;
    display: flex;
    flex-direction: column; /* Align content vertically */
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 0 2rem;
    box-sizing: border-box;
  }
  
  /* Text container */
  .home-content {
    max-width: 800px;
    margin-bottom: 2rem; /* Add space between main text and footer */
  }
  
  /* Heading styles */
  .home-content h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  
  /* Paragraph styles */
  .home-content p {
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 2.5rem;
    line-height: 1.6;
  }
  
  /* Button styles */
  .quote-button {
    background-color: black;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    opacity: 0.8; /* Set opacity to 70% */
    transition: opacity 0.3s, transform 0.2s, background-color 0.3s;
  }
  
  .quote-button:hover {
    background-color: black;
    transform: scale(1.05);
    opacity: 1;
  }
  
  /* Footer section for expertise and experience */
  .home-footer {
    display: flex;
    justify-content: center;
    gap: 2rem; /* Space between footer items */
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2); /* Optional divider */
  }
  
  .footer-item {
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Space between icon and text */
  }
  
  .footer-item span {
    font-size: 1.25rem; /* Slightly larger for emphasis */
  }
  