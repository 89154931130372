.statistics {
    display: flex;
    justify-content: space-evenly; /* Spreads items evenly */
    align-items: center;
    background-color: #c19a6b;
    padding: 2rem 3rem;
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Allow horizontal scrolling if the content overflows */
    gap: 1rem; /* Adds spacing between items */
  }
  
  .stat-item {
    text-align: center;
    min-width: 100px; /* Ensure each stat has a minimum width */
    flex: 1; /* Distribute space evenly among items */
  }
  
  .stat-value {
    font-size: 2.5rem; /* Adjusted for responsiveness */
    font-weight: bold;
    color: white; /* White text */
    margin: 0;
  }
  
  .stat-label {
    font-size: 1rem; /* Adjusted for smaller screens */
    font-weight: normal;
    color: white; /* White text */
    margin-top: 0.3rem;
  }
  
  /* Responsive Design: Adjust layout for smaller screens */
  @media (max-width: 768px) {
    .statistics {
      padding: 1rem 1.5rem; /* Reduce padding for smaller screens */
      gap: 0.5rem; /* Less gap between items */
    }
  
    .stat-item {
      min-width: 80px; /* Smaller min-width for tighter spacing */
    }
  
    .stat-value {
      font-size: 2rem; /* Slightly smaller font for numbers */
    }
  
    .stat-label {
      font-size: 0.9rem; /* Adjust label text size */
    }
  }
  
  @media (max-width: 480px) {
    .statistics {
      padding: 0.5rem 0.8rem; /* Further reduce padding */
    }
  
    .stat-value {
      font-size: 1.8rem; /* Further reduce font size for very small screens */
    }
  
    .stat-label {
      font-size: 0.8rem; /* Smaller text for labels */
    }
  }
  