/* Main Container */
.contact {
    background-color: #f9f9f9;
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    max-width: 1200px;
    width: 100%;
  }
  
  /* Left Section */
  .contact-info {
    text-align: left;
  }
  
  .contact-heading {
    font-size: 1rem;
    color: #6c63ff;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
  
  .contact-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .contact-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
    line-height: 1.5;
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .detail-item {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .detail-icon {
    font-size: 1.5rem;
    color: #6c63ff;
  }
  
  .detail-item h5 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .detail-item p {
    margin: 0;
    font-size: 0.9rem;
    color: #666;
  }
  
  /* Right Section */
  .contact-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-input,
  .form-textarea {
    width: 90%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    color: #333;
  }
  
  .form-input:focus,
  .form-textarea:focus {
    outline: none;
    border-color: #6c63ff;
    box-shadow: 0 0 4px rgba(108, 99, 255, 0.3);
  }
  
  .form-textarea {
    resize: none;
  }
  
  .form-button {
    width: 100%;
    padding: 0.8rem;
    background: #6c63ff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form-button:hover {
    background: #5754d6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-container {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
  